<template>
    <div class="facorites-box" @click.stop>
        <div class="facorites-card">
            <p class="title">
                <span class="text-f16 font-medium">添加到收藏夹</span>
                <icon-close class="cursor-pointer" :style="{ color: '#dddddd' }" :size="16" @click="props.close" />
            </p>
            <div class="list">
                <a-radio-group v-model="data.currentId">
                    <a-radio value="1">
                        <template #radio="{ checked }">
                            <div class="radio-item hidden_row1">
                                <div class="flex items-center">
                                    <img v-show="checked" src="@/assets/icons/DecorationCircle-collect-selected.svg">
                                    <img v-show="!checked" src="@/assets/icons/DecorationCircle-collect-Unselected.svg">
                                    <span>全部</span>
                                </div>
                                <div>{{ data.favoriteData.fileNum }}</div>
                            </div>
                        </template>
                    </a-radio>
                    <a-radio v-for="item in data.favoriteData.folders" :value="item.id">
                        <template #radio="{ checked }">
                            <div class="radio-item hidden_row1">
                                <div class="flex items-center">
                                    <img v-show="checked" src="@/assets/icons/DecorationCircle-collect-selected.svg">
                                    <img v-show="!checked" src="@/assets/icons/DecorationCircle-collect-Unselected.svg">
                                    <span>{{ item.folderName }}</span>
                                </div>
                                <div>{{ item.fileNum }}</div>
                            </div>
                        </template>
                    </a-radio>
                </a-radio-group>
            </div>
            <div class="add">
                <div class="ipt-box" v-if="data.iptShow">
                    <!-- <n-input ref="ipt" class="ipt" autofocus v-model:value="data.favoriteName" @keydown.enter="addItem"
                        placeholder="请输入收藏夹名称" /> -->
                    <input type="text" 
                        class="ipt" 
                        ref="ipt" 
                        v-model="data.favoriteName" 
                        @keydown.enter="addItem"
                        placeholder="请输入收藏夹名称"
                        >
                    <n-button class="btn" :color="data.favoriteName ? '#F39E00' : '#CBCBCB'" @click="addItem">新建</n-button>
                </div>
                <div class="ipt-icon cursor-pointer" v-else @click="data.iptShow = true">
                    <icon-plus-circle-fill :style="{ color: '#F39E00', marginRight: '8px' }" :size="22" />
                    <span class="text-f16">新建文件夹</span>
                </div>
            </div>
            <div class="confirm">
                <n-button class="btn" color="#000000" @click="confirm">确认</n-button>
                <n-button class="btn" @click="props.close">取消</n-button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FavoriteApi, MagicHouseFavoriteFileDto } from '@/apis/api'
import { useMessage } from 'naive-ui'
const message = useMessage()
const favoriteApi = new FavoriteApi()
const props = defineProps(['open', 'close', 'id'])

interface dataType {
    iptShow: boolean,
    currentId: string,
    favoriteName: string,
    favoriteData: MagicHouseFavoriteFileDto
}

const data = reactive<dataType>({
    iptShow: false,
    currentId: '1',  //当前选中文件夹
    favoriteName: "",//新增收藏夹name
    favoriteData: {}
})
// 获取收藏夹列表
async function getList() {
    let res = await favoriteApi.apiAppFavoriteGetOwnFolderPost()
    data.favoriteData = res.data
}

// 添加收藏夹
async function addItem() {
    if (!data.favoriteName) {
        message.error('名称不能为空')
        return
    } 
    if(data.favoriteName.length > 10){
        message.error('收藏夹名称不能超过10个字')
        return
    }
    let res = await favoriteApi.apiAppFavoriteFavoriteFolderPost({
        folderName: data.favoriteName
    })
    data.iptShow = false
    data.favoriteName = ''
    getList()
}
// 确认收藏夹
async function confirm() {
    if (data.currentId === '1') {
        props.close()
        return
    }
    let res = await favoriteApi.apiAppFavoriteRemoveFavoriteFolderPost({
        contentIds: props.id,
        targetId: data.currentId
    })
    props.close()
}

onMounted(() => {
    getList()
})
</script>

<style lang="less" scoped>
.facorites-box {
    @apply fixed flex justify-center items-center;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.facorites-card {
    @apply bg-w flex flex-col;
    width: 480px;
    height: 456px;
    border-radius: 8px;
    box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.05);
    overflow: hidden;

    .title {
        @apply box-border flex justify-between items-center;
        height: 50px;
        padding: 0 26px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .list {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;

        :deep(.arco-radio-group) {
            @apply box-border;
            width: 480px;
            height: 100%;
            display: block;
            margin-right: 0;
        }

        :deep(.arco-radio) {
            @apply box-border;
            width: 100%;
            height: 44px;
            padding: 0;
            margin-right: 0;
        }

        .radio-item {
            @apply flex items-center justify-between box-border text-f14;
            width: 100%;
            height: 100%;
            padding: 0 24px;

            img {
                margin-right: 8px;
            }
        }
    }

    .add {
        @apply box-border;
        height: 64px;
        padding: 0 26px;

        .ipt-box {
            @apply flex items-center;
            width: 100%;
            height: 100%;

            .ipt {
                flex: 1;
                height: 32px;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #ccc;
                padding-left: 13px;
            }

            .btn {
                border-radius: 0px 4px 4px 0px;
            }
        }

        .ipt-icon {
            @apply flex items-center;
            height: 100%;
            color: #F39E00;
        }
    }

    .confirm {
        @apply box-border flex items-center justify-end;
        height: 74px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        padding: 0 26px;

        .btn {
            &:nth-last-of-type(1) {
                margin-left: 10px;
            }
        }
    }

    .btn {
        width: 78px;
        height: 32px;
    }
}
</style>